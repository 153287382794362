import React, { useContext } from 'react'
import logo from '../img/tiptop.png'
import { Link } from 'react-router-dom'
import '../scss/Navbar.scss'
import { AppContext } from '../context/AppContextProvider'
import { useLocation } from 'react-router-dom'

const Navbar = (props) => {
    const { logout } = useContext(AppContext)
    const { pathname } = useLocation()

    return (
        <div>
            <div id="nav" className="is-clearfix">            
                <h2><span>PS Jalan Kolam Air Lama Dashboard</span> by TipTop</h2>		
            </div>

            <ul id="subnav" className="is-clearfix">
                <li className="is-pulled-left"><img src={logo} alt="" /></li>

                <li className={`is-pulled-left ${pathname === '/' ? 'is-active' : ''}`}>
                    <Link to="/">Dashboard</Link>						
                </li>

                <li className={`is-pulled-left ${pathname === '/monitor' ? 'is-active' : ''}`}>						
                    <Link to="/monitor">Real-time Monitoring</Link>
                </li>

                <li className={`is-pulled-left ${pathname === '/facecore' ? 'is-active' : ''}`}>
                    <Link to="/facecore">Facial Recognition</Link>						
                </li>			

                <li className="auth is-pulled-right logout">
                    <a onClick={() => logout()}>Logout</a>
                </li>
            </ul>
        </div>        
    )
}

export default Navbar