import React, { useState, useEffect } from 'react'
import Dashboard from './components/Dashboard'
import Monitor from './components/Monitor'
import PrivateRoute from './components/PrivateRoute'
import Facecore from './components/Facecore'
import AppContextProvider from './context/AppContextProvider'
import Navbar from './components/Navbar'
import Login from './components/Login'
import pumpGroupsData from './data/pumpgroups'
import violationsData from './data/violations'
import './scss/App.scss'
import axios from 'axios'
import io from 'socket.io-client'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'

const socket = io(process.env.REACT_APP_TIPTOP_WEBSOCKET_SERVER)

const App = (props) => {
	const [pumpGroups, setPumpGroups] = useState(pumpGroupsData)
	const [violations, setViolations] = useState(violationsData)
	const [selectedPump, setSelectedPump] = useState(0)
	const [zoomedItem, setZoomedItem] = useState(null)
	const [visits, setVisits] = useState([])
	const [people, setPeople] = useState([])		

	const selectPump = (pump) => {				
		setSelectedPump(pump)
		console.log(selectedPump)
	}

	const zoom = (zoomedItem) => {
		setZoomedItem(zoomedItem)
	}

	const closeOverlay = () => {
		setZoomedItem(null)
	}

	const imageSrc = (item) => {
		if (item.image.includes('/cam') || item.image.includes('/facecore') || item.image.includes('/img/'))
			return item.image

		const components = item.image.split('/')
		const filename = components[components.length - 1]
		return `${process.env.REACT_APP_TIPTOP_API}/${filename}`
	}

	useEffect(() => {
		console.log('useEffect')

		// const interval = setInterval(() => {
		// 	console.log('hai')
		// 	const obj = {image: "/home/inference/pump-anomaly/images/15861696448092.jpg", name: null, timestamp: 1586169644}			
		// 	setPeople(people => [obj, ...people])
		// }, 1000)		

		// Select initial pump
		let selectedPump

		pumpGroups.forEach((pumpGroup) => {
			const pump = pumpGroup.find(pump => pump.name === 'Pump 12')

			if (typeof pump !== 'undefined')
				selectedPump = pump
		})
		
		setSelectedPump(selectedPump)		

		// Load data from API

		const loadVisits = async () => {
			const response = await axios.get(`${process.env.REACT_APP_TIPTOP_API}/visits`)		
			setVisits(response.data)
		}

		const loadPeople = async () => {
			const response = await axios.get(`${process.env.REACT_APP_TIPTOP_API}/people`)					
			setPeople(response.data)
		}

		loadVisits()
		loadPeople()
		
		socket.on('connect', () => {
			console.log('connected to websocket server')
		})

		socket.on('monitor', (obj) => {
			console.log(obj)			

			if (typeof obj.pump_name !== 'undefined') {
				setVisits(visits => [obj, ...visits])	
			}				
			else {
				setPeople(people => [obj, ...people])				
			}					
		})

		// return () => clearInterval(interval)
	}, [])	
	
	return (
		<Router>
			<div id="app" className="is-hidden-touch">
				<AppContextProvider>
					<Switch>
						<PrivateRoute path="/monitor">
							<Navbar />
							<Monitor 							
								visits={visits}
								violations={violations}
								pumpGroups={pumpGroups}				
								selectedPump={selectedPump} 					
								selectPump={selectPump}
								zoom={zoom}	
							/>
						</PrivateRoute>

						<PrivateRoute path="/facecore">
							<Navbar />
							<Facecore people={people} />
						</PrivateRoute>

						<Route path="/login">
							<Login />
						</Route>

						<PrivateRoute path="/">
							<Navbar />
							<Dashboard zoom={zoom} />
						</PrivateRoute>
					</Switch>																	

					{zoomedItem &&				
						<div id="overlay" onClick={closeOverlay}>
							<div id="inner">
								<img src={imageSrc(zoomedItem)} alt="" />
							</div>			
						</div>
					}
				</AppContextProvider>				
			</div>
		</Router>		   		
	)	
}

export default App
