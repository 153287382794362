import React from 'react'
import '../scss/UAUC.scss'
import moment from 'moment'

const UAUC = (props) => {
    const imageSrc = (violation) => {
        return violation.image
    }

    const formattedDateTime = (datetime) => {
        return moment(datetime['$date']).utc().format('DD/MM/YYYY hh:mm:ssa')
    }
    
    return (
        <ul id="violations">
            {
                props.violations.map((violation) => {
                    return (
                        <li key={violation._id['$oid']} className="is-clearfix">
                            <img 
                                onClick={() => props.zoom(violation)}
                                src={imageSrc(violation)} 
                                alt="" 
                                className="is-pulled-left zoomable" 
                            />
                            <div className="right is-pulled-left">
                                <h2>{violation.name}</h2>
                                <h3>{formattedDateTime(violation.timestamp)}</h3>
                            </div>
                        </li>
                    )
                })
            }                
        </ul>
    )    
}

export default UAUC