export default [
    [
        {
            name: 'Pump 6',
            color: 'grey'						
        },
        {
            name: 'Pump 5',
            color: 'grey'
        }
    ],	
    [
        {
            name: 'Pump 4',
            color: 'grey'
        },
        {
            name: 'Pump 3',
            color: 'grey'						
        }
    ],	
    [
        {
            name: 'Pump 2',
            color: 'grey'
        },
        {
            name: 'Pump 1',
            color: 'grey'						
        }
    ],	
    [
        {
            name: 'Pump 12',
            color: 'green',
            rtspPath: 'rtsp://admin:admin123@192.168.0.4:554/cam/realmonitor?channel=1&subtype=0',
            mjpegUrl: 'https://tiptop-live.inference.asia/cam1'
        },
        {
            name: 'Pump 11',
            color: 'green',
            rtspPath: 'rtsp://admin:admin123@192.168.0.4:554/cam/realmonitor?channel=2&subtype=0',
            mjpegUrl: 'https://tiptop-live.inference.asia/cam2'
        }
    ],	
    [
        {
            name: 'Pump 10',
            color: 'green',
            rtspPath: 'rtsp://admin:admin123@192.168.0.4:554/cam/realmonitor?channel=2&subtype=0',
            mjpegUrl: 'https://tiptop-live.inference.asia/cam2'
        },
        {
            name: 'Pump 9',
            color: 'green',
            rtspPath: 'rtsp://admin:admin123@192.168.0.4:554/cam/realmonitor?channel=2&subtype=0',
            mjpegUrl: 'https://tiptop-live.inference.asia/cam2'
        }
    ],
    [
        {
            name: 'Pump 8',
            color: 'green',
            rtspPath: 'rtsp://admin:admin123@192.168.0.4:554/cam/realmonitor?channel=3&subtype=0',
            mjpegUrl: 'https://tiptop-live.inference.asia/cam3'
        },
        {
            name: 'Pump 7',
            color: 'green',
            rtspPath: 'rtsp://admin:admin123@192.168.0.4:554/cam/realmonitor?channel=3&subtype=0',
            mjpegUrl: 'https://tiptop-live.inference.asia/cam3'
        }
    ]
]			