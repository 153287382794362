import React, { useState, useContext } from 'react'
import '../scss/Login.scss'
import logo from '../img/tiptop.png'
import axios from 'axios'
import { AppContext } from '../context/AppContextProvider'

const Login = (props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [identifier, setIdentifier] = useState('')
    const [password, setPassword] = useState('')
    const { login } = useContext(AppContext)

    const submit = async (e) => {
        e.preventDefault()
        setIsSubmitting(true)

        try {
            const response = await axios.post('https://tiptop-api.inference.asia/auth/local', { identifier, password })
            
            login({
                user: response.data.user,
                jwt: response.data.jwt
            })

            window.location = '/'
        }        
        catch {
            setIsSubmitting(false)
            alert('Incorrect username or password')
        }
    }

    return (
        <div id="login">            
            <div id="boxy" className={isSubmitting ? 'is-submitting' : ''}>                   
                <img id="logo" src={logo} alt="" />                
                         
                <div id="inner">
                    <form onSubmit={submit}>
                        <div className="wrap">
                            <input type="text" placeholder="Email" value={identifier} onChange={e => setIdentifier(e.target.value)} />
                        </div>

                        <div className="wrap">
                            <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                        </div>
                                                
                        <div className="wrap">
                            <button disabled={isSubmitting}>Login</button>
                        </div>                        
                    </form>                    
                </div>

                <div id="bottom">
                    Powered by Inference
                </div>
            </div>
        </div>        
    )
}

export default Login