import React, { useEffect } from 'react'
import '../scss/Facecore.scss'
import moment from 'moment'
// import { loadStripe } from '@stripe/stripe-js'
 

const Facecore = (props) => {
    useEffect(() => {        
        // loadStripe('pk_live_t9Dpobxmy2PVWZav56vtIoMt000ufdO864').then(stripe => {
        //     stripe.redirectToCheckout({
        //         sessionId: 'cs_live_xaSnz3mWHSRs3VCsdhDNmMpKnoBomMYoGRHf4xYYKNordgrrmVEpgtOQ'
        //     }).then((result) => {
        //         // If `redirectToCheckout` fails due to a browser or network
        //         // error, display the localized error message to your customer
        //         // using `result.error.message`.
        //         console.log(result)
        //     })
        // })        
    }, [])

    const key = (person) => {
        return `${person.image}_${moment().unix()}_${Math.random()}`
    }

    const styleFor = (person) => {
        return {
            backgroundSize: 'cover',
            backgroundImage: `url("${imageSrc(person)}")`
        }
    }

    const imageSrc= (item) => {
        const components = item.image.split('/')
        const filename = components[components.length - 1]
        return `${process.env.REACT_APP_TIPTOP_API}/${filename}`
    }

    const matchedStyleFor = (person) => {
        let url

        if (person.name == 'Unnamed_KP_1')				
            url = `${process.env.REACT_APP_TIPTOP_API}/faces/Mohd_Ricky_Zabar`
        else
            url = `${process.env.REACT_APP_TIPTOP_API}/faces/${person.name}`

        return {
            backgroundSize: 'cover',
            backgroundImage: `url("${url}")`
        }
    }

    const formattedDateTime = (unixtime) => {
        return moment.unix(unixtime).format('hh:mm:ssa')        
    }

    const formattedFullDateTime = (unixtime) => {
        return moment.unix(unixtime).format('DD/MM/YYYY hh:mm:ssa')        
    }     

    const matches = () => {
        return props.people.filter(person => typeof(person.name) !== 'undefined' && person.name != null).slice(0, 50)
    }

    const faces = () => {
        return props.people.slice(0, 50)
    }

    const matchName = (m) => {			
        const name = m.name.replace(/_/g, ' ')
        
        if (name == 'Unnamed KP 1')
            return 'Mohd Ricky Zabar'
        
        return name
    }

    let matchedPeople

    if (matches().length > 0) {
        matchedPeople = <ul id="matches">
            {
                matches().map((m) => {
                    return (
                        <li key={key(m)} className="is-clearfix">
                            <div className="left is-pulled-left" style={styleFor(m)}></div>
                            <div className="middle is-pulled-left">
                                <h2>{matchName(m)}</h2>
                                <h3>{formattedFullDateTime(m.timestamp)}</h3>
                            </div>
                            <div className="right is-pulled-left" style={matchedStyleFor(m)}></div>
                        </li>
                    )
                })
            }                
        </ul>
    }
    else {
        matchedPeople = <p>No match so far</p>
    }

    return (
        <div id="facecore" className="is-pulled-left">
            <div id="left" className="is-pulled-left">
                <div id="top">
                    <h1>Live View</h1>
                    <div id="imgwrap">
                        <img src={`${process.env.REACT_APP_TIPTOP_API}/facecore`} alt="" />
                    </div>
                </div>

                <div id="bottom">
                    <h1>Captured Faces</h1>

                    <ul id="faces">
                        {
                            faces().map((person) => {
                                return (
                                    <li key={key(person)}>
                                        <div className="img" style={styleFor(person)}></div>						
                                        <h2>{formattedDateTime(person.timestamp)}</h2>
                                    </li>
                                )
                            })
                        }                            
                    </ul>
                </div>
            </div>

            <div id="right" className="is-pulled-left">
                <h1>Matches</h1>
                {matchedPeople}                    
            </div>
        </div>
    )    
}

export default Facecore