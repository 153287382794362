import React from 'react'
import '../scss/Visits.scss'
import moment from 'moment'

const Visits = (props) => {
    const filteredVisits= () => {
        return props.visits.filter(visit => visit.pump_name === props.selectedPump.name)
    }  

    const formattedDateTime = (datetime) => {
        return moment(datetime['$date']).utc().format('DD/MM/YYYY hh:mm:ssa')
    }

    const duration = (visit) => {
        const timein = moment(visit.time_in['$date']).utc().unix()
        const timeout = moment(visit.time_out['$date']).utc().unix()			
        return moment().startOf('day').seconds(timeout - timein).format('HH:mm:ss')			
    }    

    return (
        <div id="summary" className="is-clearfix">
            <div id="sl" className="is-pulled-left">
                <div>
                    <h2>{ props.selectedPump.name } Live View</h2>
                    <div id="imgdiv">
                        <img 
                            className="zoomable"
                            onClick={() => props.zoom({ image: props.selectedPump.mjpegUrl })}
                            src={props.selectedPump.mjpegUrl} 
                            alt="" 
                        />	
                    </div>                        
                    <p>{ props.selectedPump.rtspPath }</p>
                </div>						
            </div>

            <div id="sr" className="is-pulled-left">
                <table>
                    <thead>		
                        <tr>
                            <th width="20%">Time In</th>
                            <th width="20%">Time Out</th>
                            <th width="20%">Duration</th>
                            <th width="20%">Snapshot</th>
                            <th width="20%">Status</th>
                        </tr>														
                    </thead>
                </table>

                <div id="scroller">
                    <table>
                        <tbody>
                            {
                                filteredVisits().map((visit) => {
                                    return (
                                        <tr key={visit._id['$oid']}>	                                                															                                                															
                                            <td width="20%">{formattedDateTime(visit.time_in)}</td>
                                            <td width="20%">{formattedDateTime(visit.time_out)}</td>
                                            <td width="20%">{duration(visit)}</td>
                                            <td width="20%"><a onClick={() => props.zoom(visit)}>View</a></td>
                                            <td width="20%">Normal</td>
                                        </tr>
                                    )
                                })
                            }    
                        </tbody>
                    </table>
                </div>	                                    					               
            </div>
        </div>
    )    
}

export default Visits