import React, { createContext, useState } from 'react'

export const AppContext = createContext()

const AppContextProvider = (props) => {
	const [auth, setAuth] = useState({
		user: localStorage.getItem('user'),
		jwt: localStorage.getItem('jwt')
	})

	const login = (auth) => {
		setAuth(login)
		localStorage.setItem('user', login.user)
		localStorage.setItem('jwt', login.jwt)
	}

	const logout = () => {
		localStorage.removeItem('user')
		localStorage.removeItem('jwt')
		window.location = '/login'
	}

	return (
		<AppContext.Provider value={{ auth, login, logout }}>
			{props.children}
		</AppContext.Provider>
	)
}

export default AppContextProvider