import React from 'react'
import Visits from './Visits'
import UAUC from './UAUC'
import '../scss/Monitor.scss'
import pumpIcon from '../img/pump.png'

const Monitor = (props) => {    
    const selectPump = (pump) => {
        if (pump.color !== 'green')
            return

        props.selectPump(pump)
    }        
	                          
    return (
        <div id="monitor" className="is-clearfix">
            <div id="left" className="is-pulled-left">
                <div id="top">
                    <h1>Pump Status</h1>                        
                    <ul id="pumps" className="is-clearfix">
                        { 
                            props.pumpGroups.map((pumpGroup) => {
                                return (                 
                                    <li key={pumpGroup[0].name} className="is-clearfix is-pulled-left">
                                        <div className={`pump is-pulled-left ${props.selectedPump && props.selectedPump.name === pumpGroup[0].name ? 'is-active' : ''}`} onClick={() => selectPump(pumpGroup[0])}>
                                            <img src={pumpIcon} alt="" />
                                            <h2>{pumpGroup[0].name}</h2>
                                            <div className={`circle ${pumpGroup[0].color}`}></div>
                                        </div>	

                                        <div className={`pump is-pulled-left ${props.selectedPump && props.selectedPump.name === pumpGroup[1].name ? 'is-active' : ''}`} onClick={() => selectPump(pumpGroup[1])}>
                                            <img src={pumpIcon} alt="" />
                                            <h2>{pumpGroup[1].name}</h2>
                                            <div className={`circle ${pumpGroup[1].color}`}></div>
                                        </div>
                                    </li>             
                                )
                            })                  
                        }
                    </ul>                        
                </div>
                                        
                <div id="bottom">
                    <h1>Summary</h1>   

                    {props.selectedPump &&
                        <Visits 
                            visits={props.visits} 
                            selectedPump={props.selectedPump}
                            zoom={props.zoom}
                        />
                    }                                            
                </div>
            </div>

            <div id="right" className="is-pulled-left">
                <h1>Unsafe Acts & Unsafe Conditions</h1>  
                <UAUC 
                    violations={props.violations}                        
                    zoom={props.zoom}
                />                  
            </div>                
        </div>
    )	
}

export default Monitor
