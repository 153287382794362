export default [
    {
        _id: { '$oid': '999f1aea9ss33sssseee332a220006' },
        name: 'Mobile Phone Usage (1)',
        timestamp: { '$date': 1584365751277 },
        image: '/img/vlcsnap-2020-03-16-18h17m14s259.png'
    },	
    {
        _id: { '$oid': '999f1aea9sssssssss3ddd2a220006' },
        name: 'Smoking Near Petrol Pumps (1)',
        timestamp: { '$date': 1584365600277 },
        image: '/img/vlcsnap-2020-03-16-18h12m35s457.png'
    },		
    {
        _id: { '$oid': '999f1aea9232ssssss32a220006' },
        name: 'Mobile Phone Usage (1)',
        timestamp: { '$date': 1584365559277 },
        image: '/img/vlcsnap-2020-03-16-18h11m18s080.png'
    },
    {
        _id: { '$oid': '999f1a444ea9ff33e59f32a220006' },
        name: 'Mobile Phone Usage (1)',
        timestamp: { '$date': 1584365497277 },
        image: '/img/vlcsnap-2020-03-16-17h43m59s071.png'
    }
]