import React, { useContext } from 'react'
import { AppContext } from '../context/AppContextProvider'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ children, ...rest }) => {
    const { auth } = useContext(AppContext)

    return (
      <Route
        {...rest}
        render={({ location }) => {            
            if (auth.user == null || auth.jwt == null)
                return <Redirect to={{ pathname: "/login", state: { from: location }}} />                
            else
                return children            
        }}        
      />
    )
  }

  export default PrivateRoute