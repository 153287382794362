import React from 'react'
import avd from '../img/avd.png'
import nov from '../img/nov.png'
import visits from '../img/visits.png'
import '../scss/Dashboard.scss'

const Dashboard = (props) => {	
    return (
        <ul id="dashboard" className="is-clearfix">
            <li className="is-pulled-left">
                <h1>Live stream (outside)</h1>

                <div className="wrap">
                    <div className="imgblack">
                        <img
                            className="zoomable"
                            src={`${process.env.REACT_APP_TIPTOP_API}/cam1`}
                            onClick={() => props.zoom({ image: `${process.env.REACT_APP_TIPTOP_API}/cam1` })} 
                            alt="" 
                        />
                    </div>                    
                </div>
            </li>
            
            <li className="full is-pulled-left">
                <h1>In a Glance</h1>

                <div className="wrap">
                    <ul className="numbers is-clearfix">
                        <li className="is-pulled-left br bb">
                            <h2><span>Total Number of cases this week</span></h2>
                            <h3>12</h3>
                        </li>

                        <li className="is-pulled-left br bb">
                            <h2><span>Number of open cases</span></h2>
                            <h3>1</h3>
                        </li>

                        <li className="is-pulled-left bb">
                            <h2><span>Number of closed cases</span></h2>
                            <h3>11</h3>
                        </li>

                        <li className="is-pulled-left br">
                            <h2><span>Total downtime this week</span></h2>
                            <h3>1.5hr</h3>
                        </li>

                        <li className="is-pulled-left br">
                            <h2><span>Est. Number of Vehicles</span></h2>
                            <h3>-</h3>
                        </li>

                        <li className="is-pulled-left">
                            <h2><span>Est. Number of Customers</span></h2>
                            <h3>-</h3>
                        </li>                    
                    </ul>              
                </div>
            </li>        

            <li className="is-pulled-left">
                <h1>Live stream (inside)</h1>
                <div className="wrap">
                    <div className="imgblack">
                        <img 
                            className="zoomable"
                            src={`${process.env.REACT_APP_TIPTOP_API}/facecore`}
                            onClick={() => props.zoom({ image: `${process.env.REACT_APP_TIPTOP_API}/facecore` })} 
                            alt="" 
                        />
                    </div>                    
                </div>
            </li>

            <li className="is-pulled-left">
                <h1>Total No. of visits</h1>

                <div className="wrap">
                    <img src={nov} alt="" />
                </div>
            </li>

            <li className="is-pulled-left">
                <h1>Average Visit Duration</h1>

                <div className="wrap">
                    <img src={avd} alt="" />
                </div>
            </li>

            <li className="is-pulled-left">
                <h1>All Visit</h1>

                <div className="wrap">
                    <img src={visits} alt="" />
                </div>
            </li>        
        </ul>
    )	
}

export default Dashboard
